import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from 'gatsby'


// import gsap from 'gsap'
import { StaticImage } from "gatsby-plugin-image"
// import "../styles/pages/links.scss"
import "../styles/pages/links.scss"
import Quote from "../components/quote";





 





// “Sound of Ceres have already established a panache for world - building, and their pseudo - orchestral lounge pop suites dazzle more often than not. “ AllMusic, ⅘


// “



const PressAssetsPage = ({ transitionStatus }) => {

    const quotes = [
        {
            content: 'a mythological dream - pop epic suffused in rich, swirling arrangements.',
            author: 'Pitchfork'
        },
        {
            content: 'Rich in synths, harps and angelic choirs, the record effectively conjures the glimmering environs of the gods, while Abramović’s distinctive voice grounds the narrative.',
            author: 'The Art Newspaper'
        },
        {
            content: 'a fever dream of fierce orchestral passages and 80s - era Lynchian set design; a metaphysical tumble into the very core of our existence via Gustav Holst\'s The Planets, and Les Baxter\'s midcentury exotica Emerald Sea.',
            author: 'Dazed'
        },
        {
            content: 'Ambitious concept album goes where no man has gone before',
            author: 'UNCUT, 8/10 '
        },
        {
            content: 'lush, woozy… full of harps, horns, and artfully treated synth sounds…. heavy echo in an unearthly bleat that carries a definite Björk influence, and all sorts of orchestral effects surround that voice.',
            author: 'Stereogum'
        },
        {
            content: 'distinct vocals intertwine with fantasy - infused dream - pop soundscapes',
            author: 'Flood Magazine'
        },
        {
            content: 'Sound of Ceres have already established a panache for world - building, and their pseudo - orchestral lounge pop suites dazzle more often than not.',
            author: 'AllMusic'
        },
        {
            content: 'whimsical, cinematic',
            author: 'Brooklyn Vegan'
        },
        {
            content: '..an ambitious-sounding odyssey about the history of the universe...',
            author: 'Stereogum'
        },
    ]

    return (
        <Layout currentPage={`press-assets`}>
            <Seo title="Press Assets" />


            <Container className={'press-assets'} maxWidth={false} >
                <Box className="left multiple-images" order={['2', '2', '1']}>
                    <StaticImage
                        alt="Karen - Photo by Michael McCleary"
                        objectFit="cover"
                        src={"../images/press-photos/sound-of-ceres-by-matthew-maddox-and-ryan-hover.jpg"}
                    />
                    <StaticImage
                        alt="Karen - Photo by Michael McCleary"
                        objectFit="cover"
                        src={"../images/live-1.jpeg"}
                    />
                    <StaticImage
                        alt="Karen - Photo by Michael McCleary"
                        objectFit="cover"
                        src={"../images/live-show/live-2.jpeg"}
                    />
                    <StaticImage
                        alt="Karen - Photo by Michael McCleary"
                        objectFit="cover"
                        src={"../images/live-show/live-4.jpeg"}
                    />
                    <StaticImage
                        alt="Karen - Photo by Michael McCleary"
                        objectFit="cover"
                        src={"../images/live-show/live-3.jpeg"}
                    />

                    <StaticImage
                        alt="Karen - Photo by Michael McCleary"
                        objectFit="cover"
                        src={"../images/live-show/live-5.jpeg"}
                    />
                    <Box mb={['1430px', null, '0px']}></Box>


                </Box>
                <Box className="right" order={['1', '1', '2']} mt={['60px', 'null', '0px']}>
                    <Typography component="h1" variant="h1" mb='21px' color="primary.dark">
                        Assets
                    </Typography>
                    <Box display='block'>
                        <a
                            href="/stage-plot.png"
                            className="button dark"
                            style={{ marginBottom: '20px', display: 'inline-block' }}
                            target="_blank"
                            rel='noopener noreferrer'
                        >

                            <Typography variant="button" color="primary.light" mb='20px'>
                                Stage Plot
                            </Typography>

                        </a>

                    </Box>
                    <Box display='block'>

                        <a
                            href="/support-stage-plot.png"
                            className="button dark"
                            style={{ marginBottom: '40px', display: 'inline-block' }}
                            target="_blank"
                            rel='noopener noreferrer'
                        >

                            <Typography variant="button" color="primary.light" mb='20px'>
                                Support Stage Plot
                            </Typography>

                        </a>

                    </Box>

                    <Box mb='50px'>
                        <Typography component="h1" mb='21px' variant="h1" color="primary.dark">
                            Video Demonstration
                        </Typography>
                        <video width="100%"
                            height="auto" controls
                            src="https://res.cloudinary.com/moon-magnet/video/upload/v1649727316/SoundOfCeresLiveExperiments_gmiyez.mp4"
                        //   src='https://res.cloudinary.com/moon-magnet/video/upload/f_auto/v1649727316/SoundOfCeresLiveExperiments_gmiyez.mp4'
                        />

                    </Box>
                    <Box display='flex' alignItems='center' gap='20px' mb='1em'>
                        <StaticImage
                            className='logo'
                            alt=""
                            objectFit="contain"
                            width={100}
                            src={"../images/logos/sunray-venus.png"}
                        />
                        <Typography component="h1" variant="h1" color="primary.dark">
                            Biography
                        </Typography>

                    </Box>


                    <Typography variant="body1" color="primary.dark">
                        <Box component="span" fontStyle='italic'>Emerald Sea</Box>,  the new stage show from New York-based audiovisual project Sound of Ceres, tells the story of how the universe comes to know itself. Through a dazzling suite of songs inspired by Les Baxter's midcentury exotica, Maurice Ravel's ballet Daphnis et Chloé, and Gustav Holst's The Planets, Emerald Sea studies intimacy on both an interpersonal scale and a cosmological one. Connection and severance, joy and grief, wonder and bewilderment all tumble through its scope. In the widest frame, the universe begins, meets itself, and ends. In the closest frame, two people encounter each other, grow close, and then separate. These stories are two views of the same fractal. In every intimacy human beings cultivate, every rush of connection, no matter how fleeting, we reenact the universe for ourselves.
                    </Typography>

                    <Typography variant="body1" color="primary.dark">
                        In its current cycle, Sound of Ceres is Derrick Bozich, songwriter, harpist, and flautist; Jacob Graham, synthesist, costumer, and light designer; k Hover, vocalist, lyricist, costumer, and choreographer; and Ryan Hover, songwriter and producer. Emerald Sea, the live show is a theatrical companion to their third full-length record, released June 17th, 2022 that featured narration by Marina Abramović.
                    </Typography >
                    <Typography variant="body1" mb='100px' component='p' color="primary.dark">
                        By: Sasha Geffen
                    </Typography>

                    <Box display='flex' alignItems='center' gap='20px' mb='1em'>
                        <StaticImage
                            className='logo'
                            alt=""
                            objectFit="contain"
                            width={100}
                            src={"../images/logos/seahorse.png"}
                        />
                        <Typography component="h1" variant="h1" color="primary.dark">
                            Press Quotes
                        </Typography>

                    </Box>

                    {quotes.map((quote, index) => <Quote key={index} content={quote.content} author={quote.author} />)}

                    <Typography variant="body1" component='p' color="primary.dark">
                        "...fanciful..."
                    </Typography>
                    <Typography variant="body2Bold" mb='50px' component='p' color="primary.dark">
                        - BROOKLYNVEGAN
                    </Typography>

                    <Typography variant="body1" component='p' color="primary.dark">
                        "Sound of Ceres have lined up quite a crucial collaborator for their new album Emerald Sea: contemporary art icon Marina Abramović. Turns out she’s quite a fan of their cosmic sound — has been since a Brooklyn show in 2017 — and was eager to narrate key passages of their LP."
                    </Typography>
                    <Typography variant="body2Bold" mb='50px' component='p' color="primary.dark">
                        - SELF-TITLED
                    </Typography>
                    <Box mb={['0', null, '300px']}></Box>



                </Box>
                {/* You can use a GatsbyImage component if the image is dynamic */}


            </Container>






        </Layout>
    )
}

export default PressAssetsPage
